import { useState } from "react";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import MinHeightTextarea from "./StyledTextArea";
import Grid from "@mui/material/Unstable_Grid2";
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { MuiFileInput } from "mui-file-input";

import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

import { StyledModal, StyledBackdrop, style } from "./StyledModal";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import axios from "axios";
import { sendTelegramMessage, addThankYou } from "./NewOrder";
import ReactGA from "react-ga4";
ReactGA.initialize([
  {
    trackingId: "G-E8S97JF89L",
  },
]);

const ErrorMsg = ({ closeToast, toastProps }) => (
  <div>
    Домен не знайдено. Зверніться за допомогою до{" "}
    <a href="https://t.me/easy_land_bot" target="_blank">
      боту
    </a>
  </div>
);

function resHtml(crm, title, imagePath, imageName, price, newPrice) {
  // const price = (Number(pers) / 100) * Number(newPrice);

  return `<div data-crm="${crm}" class="col-md-6 col-sm-12 col-exsm-12">
    <div class="pop-block">
      <div class="pop-name">
        <span>${title}</span>
      </div>
      <div class="images_block">
        <img class="pop-img" src="${imagePath}${imageName}" />
      </div>
      <div class="left">
        <div
          class="pop-old-price"
          style="
            text-decoration: line-through;
            font-size: 17px;
            font-weight: 700;
          "
        >
          Стара ціна <span>${price} грн</span>
        </div>
        <div class="pop-price"><span>${newPrice} грн</span></div>
      </div>
      <!--
        <div style="width:100%;text-align:center">
        <div class="more_info" style="display:block">Узнать подробнее</div>
        <div class="hide_more_info" style="display:none;">info</div>
        </div>
        -->
      <a
        data-crm="${crm}"
        data-price="${newPrice}"
        data-title="${title}"
        href="#"
        class="btn-popup add-to-order"
      >
        <div class="pop-button">Замовити</div>
      </a>
      <div class="btn-popup" style="display: none">
        <div class="pop-button" style="background: rgba(0, 162, 88, 0.6)">
          Додано
        </div>
      </div>
    </div>
  </div>`;
}
const start = ` <div class="container">
    <p id="dop" style="margin:30px;">
        ми також даруємо ВАМ ДОДАТКОВУ ЗНИЖКУ ДО 50% НА ТОПОВІ ТОВАРИ
        <span style="text-align:center;display: block;font-size: 12px;font-style:italic;">
              знижка надається лише на вказані товари при оформленні першого замовлення
        </span>
    </p>
    <p style="text-align:center;margin-bottom:30px;">
        *актуальну ціну уточнюйте у оператора
    </p>
    <div class="row" id="homeBlock">`;

const end = `</div></div>`;

export default function Additional({ usd }) {
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);
  const [fields, setFields] = useState([
    { productId: "", title: "", oldPrice: "", newPrice: "", photo: "" },
  ]);
  const handleAddField = () => {
    setFields([
      ...fields,
      { productId: "", title: "", oldPrice: "", newPrice: "", photo: "" },
    ]);
  };

  const handleRemoveField = (index) => {
    const newFields = [...fields];
    newFields.splice(index, 1);
    setFields(newFields);
  };

  function isFileNameValid(fileName) {
    const regex = /[а-яА-ЯіІїЇєЄёЁ]/;
    return regex.test(fileName);
  }

  const handleChange = (index, name, value) => {
    if (name === "photo" && isFileNameValid(value?.name)) {
      alert("Назва фото має бути лише англійською мовою або цифрами");
    } else {
      const newFields = [...fields];
      newFields[index][name] = value;
      setFields(newFields);
    }
  };

  // async function getIP() {
  //   const response = await fetch("https://api.ipify.org/?format=json");
  //   const data = await response.json();
  //   return data?.ip;
  // }

  const createNewTask = async (data, createDomainData) => {
    const { name, originaTelegram, domain, comment, createdAt, ip } = data;
    let myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", process.env.REACT_APP_clickUpApiKey);

    const raw = JSON.stringify({
      name: `Додаткові товари для сторінки подяки ${domain}`,
      description: `
      Ім'я: ${name}
      Телеграм: ${originaTelegram}
      Домен: ${domain}
      Коментар: ${comment}
      Дата: ${moment(createdAt).format("DD.MM.YYYY HH:mm")}
      `,
      status: "New",
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    ReactGA.event({
      category: "SITE",
      action: "NEW PRODUCTS REQUEST",
      value: 5, // optional, must be a number
    });
    fetch(
      "https://api.clickup.com/api/v2/list/900200949105/task",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        const { id } = result;
        const taskUrl = `https://app.clickup.com/t/${id}`;
        const message = `(Додаткові товари) Заявка додана успішно! ${taskUrl}`;
        createAdditionalProducts(id);
        sendTelegramMessage(message);
      })
      .catch((error) => console.log("error", error));
  };

  const createAdditionalProducts = async (id) => {
    let res = "";

    fields.map(function (el, i) {
      const html = resHtml(
        el?.productId,
        el?.title,
        "/images/dop/",
        el?.photo?.name,
        el?.oldPrice,
        el?.newPrice
      );
      res = res + html;
    });
    const fullHtml = start + res + end;
    addThankYou(id, "", "", fullHtml, "dop.txt");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // const ip = await getIP();
    const formData = new FormData(document.getElementById(event.target.id));
    const name = formData.get("name");
    const telegram = formData.get("telegram");
    const domain = formData.get("domain");
    const comment = formData.get("comment");

    let errorMessages = `Ви не заповнили обов'язкові поля: `;
    let hasError = false;

    if (!name) {
      errorMessages += `Ім'я `;
      hasError = true;
    }

    if (!telegram) {
      errorMessages += `Телеграм `;
      hasError = true;
    }

    if (!domain) {
      errorMessages += `Домен `;
      hasError = true;
    }

    if (hasError) {
      toast.error(errorMessages, {
        position: "top-center",
      });
      return;
    }

    const domainRegex = /^(?!https?:\/\/)[^\/]+$/;
    if (!domainRegex.test(domain)) {
      toast.error("Неправильний формат домену");
      return;
    }

    const { data: isHostExist } = await axios.post(
      "https://easy-land.site/check-domain.php",
      {
        domain,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    if (!isHostExist?.result) {
      toast.error(<ErrorMsg />);
      return;
    }
    const createdAt = new Date().toISOString();

    fields.forEach((field, index) => {
      console.log(field.photo, field.photo instanceof File);

      formData.append(`fields[${index}][productId]`, field.productId);
      formData.append(`fields[${index}][title]`, field.title);
      formData.append(`fields[${index}][oldPrice]`, field.oldPrice);
      formData.append(`fields[${index}][newPrice]`, field.newPrice);
      formData.append(`fields[${index}][photo]`, field.photo);
    });

    const x = await axios.post("https://easy-land.site/u.php", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    const taskData = {
      name,
      originaTelegram: telegram,
      domain,
      comment,
      createdAt,
      // ip,
    };
    try {
      createNewTask(taskData);
      setOpen(true);
      document.getElementById(event.target.id).reset();
    } catch (err) {
      const raw = JSON.stringify({
        name: "!!!ПОМИЛКА Додавання додаткових товарів ",
        description: `
        Ім'я: ${name}
        Телеграм: ${telegram}
        Домен: ${domain}
        Коментар: ${comment}
        Дата: ${moment(createdAt).format("DD.MM.YYYY HH:mm")}
        `,
        status: "New",
      });
      await sendTelegramMessage(`Помилка при додаванні заявки ${raw}`);
      console.log(err);
    }
  };

  return (
    <>
      <Container sx={{ mt: 2 }}>
        <Typography
          component="h1"
          variant="h4"
          style={{ textAlign: "center", marginBottom: "20px" }}
        >
          Заявка на додаткові товари для сторінки подяки
        </Typography>
        <Typography color={"#000000a3"} mb={2}>
          Зверніть увагу! Заявку на додаткові товари потрбіно подавати лише
          після того, як ви отримали сайт.
        </Typography>
        <Grid
          container
          spacing={{
            md: 4,
            xs: 0,
          }}
        >
          <Grid xs={12}>
            <CssBaseline />
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                component="form"
                noValidate
                onSubmit={handleSubmit}
                // sx={{ mt: 3 }}
                id="order-form"
              >
                <Grid container spacing={2}>
                  <Grid xs={12}>
                    <TextField
                      name="name"
                      required
                      fullWidth
                      id="name"
                      label="Ваше ім'я"
                      autoFocus
                      autoComplete="name"
                    />
                  </Grid>
                  <Grid xs={12}>
                    <TextField
                      name="telegram"
                      required
                      fullWidth
                      id="telegram"
                      label="Нік в телеграм"
                    />
                  </Grid>

                  <Grid xs={12}>
                    <TextField
                      fullWidth
                      id="domain"
                      label="Адреса вашого сайту (без http://)"
                      name="domain"
                      autoComplete="domain"
                      required
                    />
                  </Grid>

                  <Grid xs={12}>
                    <MinHeightTextarea minRows={3} />
                  </Grid>
                  <Typography component="h2" variant="h5" mb={1}>
                    Увага! Назва фото має бути лише англійською мовою або
                    цифрами
                  </Typography>
                  <Grid xs={12}>
                    {fields.map((field, index) => (
                      <Box
                        key={index}
                        mb={2}
                        border={1}
                        borderColor={"#ccc"}
                        p={2}
                        // sx={{
                        //     display: "grid",
                        //     flexWrap: "wrap",
                        //     alignItems: "center",
                        // }}
                      >
                        <Typography component="h2" variant="h5" mb={1}>
                          Товар {index + 1}
                        </Typography>
                        <div
                          style={{
                            width: "100%",
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                          }}
                        >
                          <TextField
                            label="ІД товару"
                            size="small"
                            value={field.productId}
                            onChange={(e) =>
                              handleChange(index, "productId", e.target.value)
                            }
                            style={{ marginRight: 10, marginBottom: 10 }}
                          />
                          <TextField
                            label="Назва товару"
                            size="small"
                            value={field.title}
                            onChange={(e) =>
                              handleChange(index, "title", e.target.value)
                            }
                            style={{ marginRight: 10, marginBottom: 10 }}
                          />
                          <TextField
                            label="Стара ціна"
                            size="small"
                            value={field.oldPrice}
                            onChange={(e) =>
                              handleChange(index, "oldPrice", e.target.value)
                            }
                            style={{ marginRight: 10, marginBottom: 10 }}
                          />
                          <TextField
                            label="Нова ціна"
                            size="small"
                            value={field.newPrice}
                            onChange={(e) =>
                              handleChange(index, "newPrice", e.target.value)
                            }
                            style={{ marginRight: 10, marginBottom: 10 }}
                          />
                          <MuiFileInput
                            value={fields[index]?.photo}
                            label="Фото"
                            name="photo"
                            size="small"
                            onChange={(e) => handleChange(index, "photo", e)}
                            style={{ marginRight: 10, marginBottom: 10 }}
                          />

                          <Button
                            startIcon={<RemoveCircleOutline />}
                            variant="contained"
                            size="small"
                            color="primary"
                            onClick={() => handleRemoveField(index)}
                          >
                            Видалити товар
                          </Button>
                        </div>
                      </Box>
                    ))}
                  </Grid>
                </Grid>
                <Button
                  startIcon={<AddCircleOutline />}
                  variant="contained"
                  color="primary"
                  onClick={handleAddField}
                >
                  Додати товар
                </Button>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Відправити
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <StyledModal
        aria-labelledby="unstyled-modal-title"
        aria-describedby="unstyled-modal-description"
        open={open}
        onClose={handleClose}
        slots={{ backdrop: StyledBackdrop }}
      >
        <Box sx={style}>
          <h2 id="unstyled-modal-title">Заявка додана успішно!</h2>
          <p id="unstyled-modal-description">
            Очікуйте на повідомлення від{" "}
            <a href="https://t.me/easy_land_bot" target="_blank">
              @easy_land_bot
            </a>{" "}
          </p>
        </Box>
      </StyledModal>
      <ToastContainer />
    </>
  );
}
