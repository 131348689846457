import { useState,useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Alert from "@mui/material/Alert";
import { Link } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import axios from "axios";

export default function TopBar({ usd }) {
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [message, setMessage] = useState(null);

  const getMessage = async () => {
    const resp = await axios.get("message.json")
    setMessage(resp.data?.message)
  }


  useEffect(() => {
    getMessage()
  }, []);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <Box sx={{ flexGrow: 1 }}>
          {
      message ? (
        <Alert variant="filled" severity="warning" icon={false}  sx={{ width: '100%','& .MuiAlert-message':{textAlign:"center", width:"inherit"} }}>
 
        <Typography variant="h5" component="h2">
       {message}
     </Typography>
     
     </Alert>
      ) : null
    }
      <AppBar position="static">
        <Toolbar>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Easy Land
            </Typography>
            <MenuItem component={Link} to="/">
              Головна
            </MenuItem>
            <MenuItem component={Link} to="/additional-products">
              Заявка на додаткові товари
            </MenuItem>
            <MenuItem component={Link} to="/qa">
              Питання та відповіді
            </MenuItem>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ justifySelf: "flex-end" }}
            >
              {usd ? `$${usd}` : ""}
            </Typography> */}
          </Box>

          <Box
            sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}
            alignItems="center"
          >
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              <MenuItem component={Link} to="/">
                Головна
              </MenuItem>
              <MenuItem
                component={Link}
                to="/additional-products"
                sx={{ flexGrow: 1 }}
              >
                Заявка на додаткові товари
              </MenuItem>
              <MenuItem component={Link} to="/qa" sx={{ flexGrow: 1 }}>
                Питання та відповіді
              </MenuItem>
            </Menu>

            <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
              Easy Land
            </Typography>
            {/* <Typography
              variant="h6"
              component="div"
              sx={{ justifySelf: "flex-end" }}
            >
              {usd ? `$${usd}` : ""}
            </Typography> */}
          </Box>
        </Toolbar>
      </AppBar>
    </Box>
  );
}
