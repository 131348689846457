import { useState, useEffect } from "react";
import { Routes, Route, Outlet, Link } from "react-router-dom";
import axios from "axios";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { clarity } from 'react-microsoft-clarity';

import NewOrder from "./NewOrder";
import TopBar from "./TopBar";
import Additional from "./Additional";
import QuestionsAnswers from "./QuestionsAnswers";

const defaultTheme = createTheme({});
clarity.init("pm4uc24ruf");
export default function App() {
  const [usd, setUsd] = useState(0);

  // useEffect(() => {
  //   axios
  //     .get(
  //       "https://cdn.jsdelivr.net/gh/fawazahmed0/currency-api@1/latest/currencies/usdt/uah.json"
  //     )
  //     .then((data) => {
  //       setUsd(parseFloat(data?.data?.uah + 1).toFixed(2));
  //     });
  // }, []);

  return (
    <ThemeProvider theme={defaultTheme}>
      <TopBar usd={usd} />
      <Routes>
        <Route path="/" element={<NewOrder usd={usd} />} />
        <Route path="additional-products" element={<Additional usd={usd} />} />
        <Route path="qa" element={<QuestionsAnswers usd={usd} />} />
      </Routes>
    </ThemeProvider>
  );
}
